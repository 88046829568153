export class HttpUtil
{
    static authLoginThenHandler(response) {
        if(response.status === 401) {
            let parser = document.createElement('a')
            parser.href = window.location.href

            HttpUtil.goToLoginPage(parser.pathname);
            return Promise.reject(response)
        }
        return response
    }

    static authThenHandler(response) {
        if(response.status === 401) {
            let TempResponse = {
                json: function() {
                    window.open('/login/tab-login', '_blank').focus();
                    return {
                        'success': false,
                        'message': "Please ensure you are logged in and try again."
                    }
                }
            }
            return Promise.resolve(TempResponse)
            // return Promise.reject(response)
        }
        return response
    }

    // static authCatchHandler(error) {
    //     if (error.status === 401) {
    //         HttpUtil.goToLoginPage();
    //         return true
    //     }
    //     return false
    // }

    static goToLoginPage(target) {
        let link = '/login'
        if (target && target !== '/') {
            link += '?target=' + target
        }
        window.location.href = link
    }

    static capitalizeWord(word) {
        return word && word[0].toUpperCase() + word.slice(1);
    }
}