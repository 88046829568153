import React, { useState, useEffect } from 'react';
import {
  Link
} from "react-router-dom";

import EditorLayout from "./common/EditorLayout";
import { HttpUtil } from '../models/http-utils';


const UsersComponent = () => {
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('info')
  const [loading, setLoading] = useState(true)

  const [userRecords, setUserRecords] = useState([])

  useEffect(() => {
    
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/user/all", {method: 'GET', credentials: 'include'})
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(Array.isArray(response['users'])) {
          setLoading(false)
          setUserRecords(response['users'])
      } else {
        if(response['message'] !== false) {
          setMessage(response['message'])
        } else {
          setMessage("An error occurred while trying to load the users list.")
        }
        setMessageType('danger')
      }
    });
  }, [])

  return (
    <>
    <EditorLayout>
      {
        loading
        ?
          <div>
            {message
            ?
              <div className={'alert alert-' + messageType + ' alert-dismissible'} role="alert">
                  <div className="alert-message">
                    {message}
                  </div>
              </div>
            :
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
          </div>
        :
        <>
          <h2 className="mb-3">Users</h2>
          <div className="card">
            <div className="card-body">
              <table className="table table-hover my-0">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th className="text-center">Active</th>
                        <th className="text-center">API Key</th>
                    </tr>
                </thead>
                <tbody>
                    {userRecords.map((value, index) => {
                        return <tr key={'user-component-row-' + index}>
                          <td>
                            <Link to={'/settings/user/' + value.id}>{value.email}</Link>
                          </td>
                          <td className="text-center">{value.active ? 'Yes' : 'No'}</td>
                          <td className="text-center">{value.apiKeyEnabled ? 'Yes' : 'No'}</td>
                        </tr>
                    })}
                </tbody>
                <tfoot>
                    <tr>
                      <td colSpan="3" className="text-center pt-4">
                          <Link to={'/settings/create-user'}>
                          <i className="fas fa-plus-circle"></i> Add User</Link>
                      </td>
                    </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </>
      }
    </EditorLayout>
    </>
  );
}

export default UsersComponent;
