import React from "react";
import {
    Link
} from "react-router-dom";

import EditorLayout from "./common/EditorLayout";
import { Constants } from '../models/constants';
import { Fields } from '../models/fields';
import { HttpUtil } from '../models/http-utils';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CreateComponent extends React.Component {
  state = {
    'component': null,
    'parent': null,
    'message': '',
    'messageType': 'info',
    'buttonsDisabled': true,
    'loading': true,
    'nextSequence': 1,
    'availableChildComponents': [],
    'arbitraryType': '',
    'defaultName': '',
  }
  componentDidMount() {
    const { pSpaceId, pParentId } = this.props.match.params
    let spaceId = parseInt(pSpaceId)
    let parentId = parseInt(pParentId)

    if (Number.isInteger(spaceId) && Number.isInteger(parentId) && spaceId > 0 && parentId > 0) {
      this.loadParentComponent(spaceId, parentId)
    }
  }
  loadParentComponent(spaceId, parentId) {
    this.setState({spaceId: spaceId, parentId: parentId})
    let newComponent = {
      [Fields.COMPONENT_SPACE_ID]: spaceId,
      [Fields.COMPONENT_PARENT_ID]: parentId,
      [Fields.COMPONENT_NAME]: '',
      [Fields.COMPONENT_DATE]: new Date().toJSON(),
    }

    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/" + parentId + "?children=summary", {method: 'GET', credentials: 'include'})
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response[Fields.COMPONENT_ID]) {
          // let nextNumber = response[Fields.COMPONENT_ITEMS].length + 1
          let availableChildComponents = [Constants.UNREAl_TYPE_ARBITRARY]
          if (response[Fields.COMPONENT_TYPE] && Constants.CHILD_COMPONENT_PARENT_TYPES.includes(response[Fields.COMPONENT_TYPE])) {
            availableChildComponents = Constants.ALLOWED_CHILD_COMPONENTS[response[Fields.COMPONENT_TYPE]].concat(availableChildComponents)
          }
          newComponent[Fields.COMPONENT_TYPE] = availableChildComponents[0]
          // newComponent[Fields.COMPONENT_KEY] = response[Fields.COMPONENT_KEY] + '-' + newComponent[Fields.COMPONENT_TYPE] + '-' + nextNumber
          newComponent[Fields.COMPONENT_KEY] = this.getDefaultKey(response, newComponent[Fields.COMPONENT_TYPE])
          let defaultName = this.getDefaultName(newComponent[Fields.COMPONENT_KEY])

          let nextSequence = 1
          if(response[Fields.COMPONENT_ITEMS].length > 0) {
            let lastChildIndex = response[Fields.COMPONENT_ITEMS].length - 1
            nextSequence = response[Fields.COMPONENT_ITEMS][lastChildIndex][Fields.COMPONENT_SEQUENCE] + 1
          }

          this.setState({parent: response, loading: false, buttonsDisabled: false, component: newComponent, nextSequence: nextSequence,
            availableChildComponents: availableChildComponents, defaultName: defaultName})
      } else {
        if(response['message'] !== false) {
          this.setState({message: response['message'], messageType: 'danger'})
        } else {
          this.setState({message: "An error occurred while saving.", messageType: 'danger'})
        }
      }
    });
  }

  handleChangeField(e, field) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.updateComponent(value, field)
  }
  updateComponent(value, field) {
    let currentComponent = JSON.parse(JSON.stringify(this.state.component))

    if (field === Fields.COMPONENT_TYPE) {
      // currentComponent[Fields.COMPONENT_KEY] = currentComponent[Fields.COMPONENT_KEY].replace(currentComponent[field], value)
      currentComponent[Fields.COMPONENT_KEY] = this.getDefaultKey(this.state.parent, value)
    }
    currentComponent[field] = value
    let update = {component: currentComponent}

    if(field === Fields.COMPONENT_TYPE || field === Fields.COMPONENT_KEY) {
      update['defaultName'] = this.getDefaultName(currentComponent[Fields.COMPONENT_KEY])
    }

    this.setState(update)
  }

  getDefaultKey(parentComponent, componentType) {
    let defaultKeyPrefix = parentComponent[Fields.COMPONENT_KEY] + '-' + componentType + '-'
    let nextNumber = 0

    while (true) {
      let keyUsed = false
      nextNumber += 1
      let possibleDefaultKey = defaultKeyPrefix + String(nextNumber)
      parentComponent[Fields.COMPONENT_ITEMS].forEach(c => {
        if (c[Fields.COMPONENT_KEY] === possibleDefaultKey) {
          keyUsed = true
        }
      })
      if(!keyUsed) {
        return possibleDefaultKey
      }
    }
  }
  getDefaultName(key) {
    let parts = key.split("-")
    let capitalizedParts = parts.map(p => HttpUtil.capitalizeWord(p))
    return capitalizedParts.join(" ")
  }

  saveContent(published) {
    this.setState({buttonsDisabled: true, messageType: 'info'});
    let updates = {
      [Fields.COMPONENT_SPACE_ID]: this.state.component[Fields.COMPONENT_SPACE_ID],
      [Fields.COMPONENT_NAME]: this.state.component[Fields.COMPONENT_NAME],
      [Fields.COMPONENT_KEY]: this.state.component[Fields.COMPONENT_KEY],
      [Fields.COMPONENT_SEQUENCE]: this.state.nextSequence,
      [Fields.COMPONENT_TYPE]: this.state.component[Fields.COMPONENT_TYPE],
      [Fields.COMPONENT_PARENT_ID]: this.state.component[Fields.COMPONENT_PARENT_ID],
      [Fields.COMPONENT_METADATA]: this.generateDefaultMetadata(),
      [Fields.COMPONENT_DATE]: this.state.component[Fields.COMPONENT_DATE],
      [Fields.COMPONENT_PUBLISHED]: published
    }

    if(!updates[Fields.COMPONENT_NAME]) {
      updates[Fields.COMPONENT_NAME] = this.state.defaultName
    }

    if(this.state.component[Fields.COMPONENT_TYPE] === Constants.UNREAl_TYPE_ARBITRARY && this.state.arbitraryType) {
      updates[Fields.COMPONENT_TYPE] = this.state.arbitraryType
    }

    let newComponent = JSON.parse(JSON.stringify(updates))
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component", {
      method: 'POST', credentials: 'include',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(newComponent),
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        if(response['id']) {
          this.props.history.push('/component/' + this.state.component[Fields.COMPONENT_SPACE_ID] + '/' + response['id']);
        } else {
          this.props.history.push('/component/' + this.state.component[Fields.COMPONENT_SPACE_ID] + '/' + this.state.component[Fields.COMPONENT_PARENT_ID]);
        }
      } else {
        if(response['message'] !== false) {
          this.setState({message: response['message'], messageType: 'danger'})
        } else {
          this.setState({message: "An error occurred while saving.", messageType: 'danger'});
        }
      this.setState({buttonsDisabled: false});
      }
    });
  }
  generateDefaultMetadata() {
    let metadata = {}
    if(this.state.component[Fields.COMPONENT_TYPE]  === Constants.TYPE_BLOCK) {
      metadata[Fields.COMPONENT_METADATA_DESKTOP_WIDTH] = Constants.DEFAULT_BLOCK_WIDTH
      metadata[Fields.COMPONENT_METADATA_MOBILE_WIDTH] = Constants.DEFAULT_BLOCK_WIDTH
      metadata[Fields.COMPONENT_METADATA_FRAME_COLOR] = Constants.DEFAULT_FRAME_COLOR
    } else if(this.state.component[Fields.COMPONENT_TYPE]  === Constants.TYPE_FOLDER) {
      metadata[Fields.COMPONENT_METADATA_FRAME_COLOR] = Constants.DEFAULT_FRAME_COLOR
    }
    return metadata
  }

  render() {
    return (
      <>
      <EditorLayout>
        {
          this.state.loading
          ?
          <div>
            {this.state.message
            ?
              <div className={'alert alert-' + this.state.messageType + ' alert-dismissible'} role="alert">
                  <div className="alert-message">
                    {this.state.message}
                  </div>
              </div>
            :
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
          </div>
          :
          <>
            <div className="row mb-2 mb-xl-3">
              <div className="col-auto d-none d-sm-block">
                  <Link to={'/component/' + this.state.component[Fields.COMPONENT_SPACE_ID] + '/' + this.state.component[Fields.COMPONENT_PARENT_ID]}>
                    <i className="fas fa-arrow-circle-left"></i> Cancel</Link>
                  <h2 className="mt-3">{this.state.component[Fields.COMPONENT_NAME]}</h2>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label className="form-label">Key</label>
                      <input type="text" className="form-control" placeholder="Key" value={this.state.component[Fields.COMPONENT_KEY]}
                        onChange={e => this.handleChangeField(e, Fields.COMPONENT_KEY)} />
                      <small className="form-text text-muted">Key must be unique space-wide.</small>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-5">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input type="text" className="form-control" placeholder={this.state.defaultName} value={this.state.component[Fields.COMPONENT_NAME]}
                        onChange={e => this.handleChangeField(e, Fields.COMPONENT_NAME)}/>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="component-date">Date</label>
                      <br/>
                      <DatePicker
                        className="form-control"
                        selected={new Date(this.state.component[Fields.COMPONENT_DATE])}
                        onChange={d => this.updateComponent(d, Fields.COMPONENT_DATE)}
                        showTimeSelect
                        dateFormat="d MMM, yyyy h:mm aaa"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Type</label>
                  <select className="form-control mb-3" value={this.state.component[Fields.COMPONENT_TYPE]}
                    onChange={e => this.handleChangeField(e, Fields.COMPONENT_TYPE)}>
                      {
                        this.state.availableChildComponents.map((key) => (
                          <option value={key} key={'type-' + key}>{Constants.TYPE_DESCRIPTIONS[key]}</option>
                        ))
                      }
                  </select>
                  {this.state.component[Fields.COMPONENT_TYPE] === Constants.UNREAl_TYPE_ARBITRARY &&
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Type name" value={this.state.arbitraryType}
                        onChange={e => this.setState({'arbitraryType': e.target.value})} />
                    </div>
                  }
                </div>
                {this.state.message &&
                  <div className="alert alert-danger alert-dismissible" role="alert">
                      <div className="alert-message">
                        {this.state.message}
                      </div>
                  </div>
                }
                <div className="text-right mt-5">
                  <button
                    disabled={this.state.buttonsDisabled}
                    onClick={e => this.saveContent(false)}
                    className="btn btn-square btn-lg btn-secondary mr-4"
                    type="button"
                  ><i className="fas fa-save mr-1"></i> Draft</button>
                  <button
                    disabled={this.state.buttonsDisabled}
                    onClick={e => this.saveContent(true)}
                    className="btn btn-square btn-lg btn-primary"
                    type="button"
                  ><i className="fas fa-save mr-1"></i> Publish</button>
                </div>
              </div>
            </div>
          </>
        }
      </EditorLayout>
      </>
    );
  }
}

export default CreateComponent;
