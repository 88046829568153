import React from "react";
import {
    Link
} from "react-router-dom";

class EditorSidebar extends React.Component {
    state = {
      'spaces': [],
    }
    componentDidMount() {
        this.loadSpaces()
    }
    loadSpaces() {
        let url = process.env.REACT_APP_API_V1_BASE_URL + '/space/all'
        fetch(url, {method: 'GET', credentials: 'include'})
        .then((response) => {
            return response.json();
        })
        .then(response => {
            if(Array.isArray(response['spaces'])) {
                this.setState({spaces: response['spaces']})
            }
        });
    }
    render() {
        return (
        <>
            <nav id="sidebar" className="sidebar">
                <div className="sidebar-content js-simplebar">
                    <a className="sidebar-brand text-center" href="/">
                        <img src="/img/logo-blue-white.png" title="Chautec CMS" alt="Chautec CMS" />
                    </a>
                    <ul className="sidebar-nav">
                        <li className="sidebar-header font-weight-bold">
                            <i className="fas fa-globe-africa mr-1"></i> SPACES 
                        </li>
                        {this.state.spaces.map(s =>
                            <li className="sidebar-item" key={'space-' + s.id}>
                                <Link to={'/component/' + s.id} className="sidebar-link">
                                    <span className="align-middle">{s.name}</span>
                                </Link>
                            </li>
                        )}
                        <li className="sidebar-item">
                            <Link to={'/create-space'} className="sidebar-link new-space-link">
                                <span className="align-middle"><span className="fas fa-plus mr-1"></span> Create Space</span>
                            </Link>
                        </li>
                        <li className="sidebar-header font-weight-bold">
                            <i className="fas fa-cogs mr-1"></i> SETTINGS
                        </li>
                        <Link to={'/settings/users'} className="sidebar-link">
                            <span className="align-middle">Users</span>
                        </Link>
                    </ul>
                </div>
            </nav>
        </>
        );
    }
}

export default EditorSidebar;
