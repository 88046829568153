import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import {
  useParams
} from "react-router-dom";

const LoginComponent = () => {

  const { pError } = useParams()
  const history = useHistory()
  const [target, setTarget] = useState("")

  useEffect(() => {
    if(window.location.search) {
      let params = new URLSearchParams(window.location.search)
      let target = params.get('target')
      if (target) {
        setTarget('?target=' + target)
      }
    }
    fetch(process.env.REACT_APP_API_BASE_URL + "/auth/check", {method: 'GET', credentials: 'include'})
    .then((response) => {
      if (response.status === 200) {
        history.push('/')
      }
    });
  }, [pError])

  return (
    <>
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">

              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <img src="/img/logo-blue-navy-small.png" title="Chautec CMS" alt="Chautec CMS" />
                  </div>
                  {pError === 'incorrect' &&
                    <div className="alert alert-danger alert-dismissible mb-4 text-center" role="alert">
                      <div className="alert-message">
                        Your login was incorrect.
                      </div>
                  </div>
                  }
                  {pError === 'tab-login' &&
                    <div className="alert alert-danger alert-dismissible mb-4 text-center" role="alert">
                      <div className="alert-message">
                        You have timed out. Login, then close this tab and resume work on your original one.
                      </div>
                  </div>
                  }
                  <div className="m-sm-4">
                    <form action={process.env.REACT_APP_API_BASE_URL + '/auth/login' + target} method="POST">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input className="form-control form-control-lg" type="email" name="email" required autoComplete="on" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input className="form-control form-control-lg" type="password" name="password" required />
                      </div>
                      <div className="text-center mt-5">
                        <input type="submit" className="btn btn-lg btn-primary" value="Sign In" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginComponent;