import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import CreateComponent from './components/CreateComponent';
import EditorComponent from "./components/EditorComponent";
import SearchComponent from "./components/SearchComponent";
import LoginComponent from './components/LoginComponent';
import UsersComponent from './components/UsersComponent';
import UserComponent from './components/UserComponent';
import SpaceComponent from './components/SpaceComponent';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login/:pError" component={LoginComponent}/>
        <Route path="/login" component={LoginComponent}/>
        <Route path="/settings/users" component={UsersComponent}/>
        <Route path="/settings/user/:pUserId" component={UserComponent}/>
        <Route path="/settings/create-user" component={UserComponent}/>
        <Route path="/component/:pSpaceId/:pId" render={() => <EditorComponent />}/>
        <Route path="/component/:pSpaceId" render={() => <EditorComponent />}/>
        <Route path="/create-component/:pSpaceId/:pParentId" component={CreateComponent}/>
        <Route path="/search-component" render={() => <SearchComponent />}/>
        <Route path="/space/:pSpaceId" component={SpaceComponent}/>
        <Route path="/create-space" component={SpaceComponent}/>
        <Route path="/" component={EditorComponent}/>
      </Switch>
    </Router>
  );
}

export default App;
