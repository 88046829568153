import React from "react";

import EditorSidebar from "./EditorSidebar";
import EditorTopNav from "./EditorTopNav";
import { HttpUtil } from '../../models/http-utils';

class EditorLayout extends React.Component {
    state = {
        'authenticated': false,
        'email': '',
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_BASE_URL + "/auth/check", {method: 'GET', credentials: 'include'})
        .then(HttpUtil.authLoginThenHandler)
        .then((response) => {
            if (response.status === 200) {
                this.setState({'authenticated': true})
                return response.text()
            }
        })
        .then((response) => {
            this.setState({'email': response})
        });
    }
    render() {
        return (
        <>
            {this.state.authenticated !== true
            ?
                <div className="p-5 text-center">
                    <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            :
            <div className="wrapper">
                <EditorSidebar/>
                <div className="main">
                    <EditorTopNav email={this.state.email} />
                    <main className="content">
                        <div className="container-fluid p-0">
                            { this.props.children }
                        </div>
                    </main>
                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row text-muted">
                            <div className="col-6 text-left">
                                <p className="mb-0">
                                © Chautec CMS
                                </p>
                            </div>
                            <div className="col-6 text-right">
                                <ul className="list-inline">
                                    <li className="list-inline-item mr-3">
                                        <a className="text-muted" href="mailto:support@chautec.com">Support</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="text-muted" href="https://cms.chautec.com/api-documentation"
                                            target="_blank" rel="noopener noreferrer">API Documentation</a>
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
            }
        </>
        );
    }
}

export default EditorLayout;
