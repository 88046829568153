export class Constants {

    static PARSE_TIME_OPTIONS = { year:'numeric', month:'short', day:'numeric', hour:'2-digit', minute:'2-digit', hour12: true }

    static DEFAULT_FRAME_COLOR = 'primary'
    static DEFAULT_BLOCK_WIDTH = '12'

    static KEY_ROOT = "root"

    static TYPE_MENU = 'menu'
    static TYPE_TITLE = 'title'
    static TYPE_PAGE = 'page'
    static TYPE_BLOCK = 'block'
    static TYPE_HTML = 'html'
    static TYPE_URL = 'url'
    static TYPE_FOLDER = 'folder'
    static TYPE_FILE = 'file'
    static TYPE_TXT = 'txt'
    static TYPE_CSS = 'css'
    static TYPE_JS = 'js'
    static TYPE_JSON = 'json'
    static TYPE_XML = 'xml'

    static UNREAl_TYPE_ARBITRARY = 'custom'
    static UNREAl_TYPE_ARBITRARY_TITLE = 'Custom'

    static TYPE_TITLES = {
        [this.TYPE_PAGE]: 'Page',
        [this.TYPE_BLOCK]: 'Block',
        [this.TYPE_HTML]: 'HTML',
        [this.TYPE_MENU]: 'Menu',
        [this.TYPE_TITLE]: 'Title',
        [this.TYPE_URL]: 'URL',
        [this.TYPE_FOLDER]: 'Folder',
        [this.TYPE_FILE]: 'File',
        [this.TYPE_TXT]: 'Text',
        [this.TYPE_CSS]: 'CSS',
        [this.TYPE_JS]: 'JS',
        [this.TYPE_JSON]: 'JSON',
        [this.TYPE_XML]: 'XML',
    }

    static TYPE_DESCRIPTIONS = {
        [this.TYPE_PAGE]: this.TYPE_TITLES[this.TYPE_PAGE] + ' — Page you can put content into.',
        [this.TYPE_BLOCK]: this.TYPE_TITLES[this.TYPE_BLOCK] + ' — Block of content.',
        [this.TYPE_HTML]: this.TYPE_TITLES[this.TYPE_HTML] + ' — Similiar to a block but using raw HTML.',
        [this.TYPE_MENU]: this.TYPE_TITLES[this.TYPE_MENU] + ' — Main menu or submenu.',
        [this.TYPE_TITLE]: this.TYPE_TITLES[this.TYPE_TITLE] + ' — Title inside a submenu.',
        [this.TYPE_URL]: this.TYPE_TITLES[this.TYPE_URL] + ' — Link to any URL from the menu.',
        [this.TYPE_FOLDER]: this.TYPE_TITLES[this.TYPE_FOLDER] + ' — Folder you can put any component into.',
        [this.TYPE_FILE]: this.TYPE_TITLES[this.TYPE_FILE] + ' — Representation of a file you upload.',
        [this.TYPE_TXT]: this.TYPE_TITLES[this.TYPE_TXT] + ' — Arbitary text file you can edit and use access via a URL.',
        [this.TYPE_CSS]: this.TYPE_TITLES[this.TYPE_CSS] + ' — Arbitary stylesheet you can edit and use access via a URL.',
        [this.TYPE_JS]: this.TYPE_TITLES[this.TYPE_JS] + ' — Arbitary script you can edit and use access via a URL.',
        [this.TYPE_JSON]: this.TYPE_TITLES[this.TYPE_JSON] + ' — Arbitary JSON you can edit and use access via a URL.',
        [this.TYPE_XML]: this.TYPE_TITLES[this.TYPE_XML] + ' — Arbitary markup you can edit and use access via a URL.',
        [this.UNREAl_TYPE_ARBITRARY]: this.UNREAl_TYPE_ARBITRARY_TITLE + ' — A custom API only type.',
    }

    static RESERVED_KEYS = [this.KEY_ROOT]

    static CHILD_COMPONENT_PARENT_TYPES = [
        this.TYPE_PAGE, this.TYPE_MENU, this.TYPE_BLOCK, this.TYPE_FOLDER,
    ]

    static ALLOWED_CHILD_COMPONENTS = {
        [this.TYPE_FOLDER]: [
            this.TYPE_FILE, this.TYPE_BLOCK, this.TYPE_PAGE, this.TYPE_HTML, this.TYPE_URL, this.TYPE_MENU,
            this.TYPE_FOLDER, this.TYPE_TXT, this.TYPE_CSS, this.TYPE_JS, this.TYPE_JSON, this.TYPE_XML
        ],
        [this.TYPE_MENU]: [this.TYPE_PAGE, this.TYPE_URL, this.TYPE_MENU, this.TYPE_TITLE],
        [this.TYPE_PAGE]: [this.TYPE_BLOCK, this.TYPE_HTML, this.TYPE_FOLDER],
        [this.TYPE_BLOCK]: [this.TYPE_BLOCK, this.TYPE_HTML],
    }
}