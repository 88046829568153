import React, { useState, useEffect } from 'react';
import {
  useParams
} from "react-router-dom";
import { useHistory } from "react-router";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import EditorLayout from "./common/EditorLayout";
import { HttpUtil } from '../models/http-utils';


let emptySpace = () => {
  return {'id': 0, 'name': ''}
}

const SpaceComponent = () => {
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('info')
  const [loading, setLoading] = useState(true)
  const [buttonsDisabled, setButtonsDisabled] = useState(true)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const [newSpace, setNewSpace] = useState(true)
  const [spaceRecord, setSpaceRecord] = useState(emptySpace())

  let { pSpaceId } = useParams()
  const history = useHistory()

  useEffect(() => {
    setSpaceRecord(emptySpace())
    let isNewSpace = !pSpaceId
    setNewSpace(isNewSpace)

    if(!isNewSpace) {
      fetch(process.env.REACT_APP_API_V1_BASE_URL + "/space/" + pSpaceId, {method: 'GET', credentials: 'include'})
      .then(HttpUtil.authThenHandler)
      .then((response) => {
        return response.json();
      })
      .then(response => {
        if(response['id']) {
            setLoading(false)
            setButtonsDisabled(false)
            setSpaceRecord(response)
        } else {
          if(response['message'] !== false) {
            setMessage(response['message'])
          } else {
            setMessage("An error occurred while saving.")
          }
          setMessageType('danger')
        }
      })
    } else {
      setLoading(false)
      setButtonsDisabled(false)
    }
  }, [pSpaceId])

  let handleChangeField = (e, field, innerKey) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    updateSpaceRecord(value, field, innerKey)
  }
  let updateSpaceRecord = (value, field, innerKey) => {
    let currentUser = JSON.parse(JSON.stringify(spaceRecord))
    if(innerKey) {
      currentUser[innerKey][field] = value
    } else {
      currentUser[field] = value
    }
    setSpaceRecord(currentUser)
  }

  let saveSpace = () => {
    setMessage('')
    setMessageType('info')
    setButtonsDisabled(true)

    if(newSpace) {
      saveNewSpace()
    } else {
      saveExistingSpace()
    }
  }

  let saveNewSpace = () => {
    let newSpace = JSON.parse(JSON.stringify(spaceRecord))
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/space", {
      method: 'POST', credentials: 'include',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(newSpace),
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        history.push('/component/' + response['id']);
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while saving.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let saveExistingSpace = () => {
    let updates = {id: spaceRecord.id, name: spaceRecord.name}

    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/space/" + spaceRecord.id, {
      method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(updates), credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        setMessage("User has been successfully saved.")
        setMessageType("success")
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while saving.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let confirmDelete = () => {
    setMessage('')
    setMessageType('info')
    setButtonsDisabled(true)
    setShowConfirmDelete(false)
    
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/space/" + spaceRecord.id, {
      method: 'DELETE', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        history.push('/')
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while deleting.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  return (
    <>
    <EditorLayout>
      {
        loading
        ?
          <div>
            {message
            ?
              <div className={'alert alert-' + messageType + ' alert-dismissible'} role="alert">
                  <div className="alert-message">
                    {message}
                  </div>
              </div>
            :
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
          </div>
        :
        <>
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <h2 className="mt-3 mb-3">{newSpace ? 'Create' : 'Edit'} Space</h2>
            </div>
            {!newSpace &&
              <div className="col-auto ml-auto text-right mt-n1">
                <button className="btn btn-danger btn-sm" onClick={()=> {setShowConfirmDelete(true)}}>
                  <i className="fas fa-trash mr-1"></i> Delete</button>
              </div>
            }
          </div>
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label className="form-label" htmlFor="space-name">Name</label>
                <input id="space-name" type="text" className="form-control" placeholder="Space Name" autoComplete="false"
                  value={spaceRecord.name} onChange={e => handleChangeField(e, 'name')}/>
              </div>
              {message &&
                <div className={'alert alert-' + messageType + ' alert-dismissible'} role="alert">
                    <div className="alert-message">
                      {message}
                    </div>
                </div>
              }
              <div className="text-right mt-4">
                <button
                  disabled={buttonsDisabled}
                  onClick={e => saveSpace()}
                  className="btn btn-square btn-lg btn-primary"
                  type="button"
                ><i className="fas fa-save mr-1"></i> Save</button>
              </div>
            </div>
          </div>
          <Modal show={showConfirmDelete} animation={false} onHide={()=> setShowConfirmDelete(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this space and all its components?
              <br/><br/>
              <span className="text-danger font-weight-bold">WARNING: This operation is irreversible.</span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=> setShowConfirmDelete(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={() => confirmDelete()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      }
    </EditorLayout>
    </>
  );
}

export default SpaceComponent;
