export class Fields {

    static COMPONENT_ID = 'id'
    static COMPONENT_NAME = 'name'
    static COMPONENT_KEY = 'key'
    static COMPONENT_TYPE = 'componentType'
    static COMPONENT_METADATA = 'metadata'
    static COMPONENT_PARENTS = 'parents'
    static COMPONENT_CONTENT = 'content'
    static COMPONENT_ITEMS = 'children'
    static COMPONENT_SEQUENCE = 'sequence'
    static COMPONENT_DATE = 'date'
    static COMPONENT_PUBLISHED = 'published'
    static COMPONENT_VISIBLE = 'visible'
    static COMPONENT_TAGS = 'tags'
    static COMPONENT_SPACE_ID = 'spaceId'
    static COMPONENT_PARENT_ID = 'parentId'

    static COMPONENT_METADATA_FRAME = 'frame'
    static COMPONENT_METADATA_FRAME_COLOR = 'frameColor'
    static COMPONENT_METADATA_COLLAPSE = 'collapse'
    static COMPONENT_METADATA_DESKTOP_WIDTH = 'lg'
    static COMPONENT_METADATA_MOBILE_WIDTH = 'col'
    static COMPONENT_METADATA_TARGET = 'target'
    static COMPONENT_METADATA_MIME_TYPE = 'mimeType'

    static COMPONENT_FOLDER = 'folder'
    static COMPONENT_PAGE = 'page'
    static COMPONENT_URL = 'url'
    static COMPONENT_BLOCK = 'block'
}