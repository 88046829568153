import React, { useState, useEffect } from 'react';
import {
  useParams,
  Link
} from "react-router-dom";
import { useHistory } from "react-router";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import MonacoEditor from 'react-monaco-editor'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'

import EditorLayout from "./common/EditorLayout";
import EditorComponentChildren from "./EditorComponentChildren"
import { Constants } from '../models/constants';
import { Fields } from '../models/fields';
import { HttpUtil } from '../models/http-utils';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TagsInput } from "react-tag-input-component";

const editorConfiguration = {
  toolbar: {
    items: [
      'heading', 'fontFamily', 'fontSize', 'fontColor', 'highlight',
      '|',
      'bold', 'italic', 'underline', 'strikethrough', 'link', 'bulletedList', 'numberedList',
      '|',
      'alignment', 'indent', 'outdent',
      '|',
      'insertTable', 'pageBreak', 'imageInsert', 'htmlEmbed', 'blockQuote', 'undo', 'redo'
    ]
  },
  language: 'en',
  image: {
    toolbar: [
      'imageTextAlternative', 'imageStyle:full', 'imageStyle:side', 'linkImage'
    ]
  },
  table: {
    contentToolbar: [
      'tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties'
    ]
  },
  link: {
    decorators: {
        // toggleDownloadable: {
        //     mode: 'manual',
        //     label: 'Downloadable',
        //     attributes: {
        //         download: 'file'
        //     }
        // },
        openInNewTab: {
            mode: 'manual',
            label: 'Open in a new tab',
            defaultValue: false,
            attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
            }
        }
    }
  },
  licenseKey: '',
};
const monacoOptions = {
  selectOnLineNumbers: true
};
const monacoArbitraryOptions = {
  wordWrap: "on",
  occurrencesHighlight: false,
  quickSuggestions: false,
  minimap: {enabled: false},
  colorDecorators: false
};

let emptyComponent = () => {
  return {
    [Fields.COMPONENT_ID]: 0, [Fields.COMPONENT_NAME]: '', [Fields.COMPONENT_KEY]: '',
    [Fields.COMPONENT_PARENTS]: [], [Fields.COMPONENT_CONTENT]: '',
    [Fields.COMPONENT_METADATA]: {},
    [Fields.COMPONENT_TYPE]: null,
    [Fields.COMPONENT_VISIBLE]: true,
    [Fields.COMPONENT_PUBLISHED]: false,
    [Fields.COMPONENT_ITEMS]: [],
    [Fields.COMPONENT_TAGS]: null,
    [Fields.COMPONENT_DATE]: new Date(),
  }
}

const EditorComponent = (props) => {
  const [component, setComponent] = useState(emptyComponent())
  const [spaceId, setSpaceId] = useState(0)
  const [buttonsDisabled, setButtonsDisabled] = useState(true)
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('info')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showConfirmMove, setShowConfirmMove] = useState(false)
  const [lockKey, setLockKey] = useState(true)
  const [loading, setLoading] = useState(true)
  const [fileExists, setFileExists] = useState(false)
  const [breadcrumb, setBreadcrumb] = useState([])
  const [spaceName, setSpaceName] = useState("")
  const [moveMessage, setMoveMessage] = useState('')
  const [moveParentId, setMoveParentId] = useState()
  const [customMetadata, setCustomMetadata] = useState('')
   
  let { pSpaceId, pId } = useParams()
  const history = useHistory()

  useEffect(() => {
    let currentSpaceId = pSpaceId
    if(!currentSpaceId) {
      currentSpaceId = 1
    }
    setSpaceId(currentSpaceId)
    setMessage('')
    setMessageType('info')
    setLoading(true)
    setComponent(emptyComponent())

    let uri = '/component/'
    if(pId) {
      uri += pId
    } else {
      uri += currentSpaceId + "/" + Constants.KEY_ROOT
    }
    fetch(process.env.REACT_APP_API_V1_BASE_URL + uri + "?children=summary&parents=all", {method: 'GET', credentials: 'include'})
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response[Fields.COMPONENT_ID]) {
        setLockKey(Constants.RESERVED_KEYS.includes(response[Fields.COMPONENT_KEY]))

        setButtonsDisabled(false)
        setLoading(false)
        setComponent(setComponentDefaults(response))

        let breadcrumbs = JSON.parse(JSON.stringify(response['parents']))
        setBreadcrumb(breadcrumbs.reverse())

        if(response[Fields.COMPONENT_TYPE] === Constants.TYPE_FILE) {
          checkForFile(response[Fields.COMPONENT_ID])
        }

        if(!Constants.TYPE_TITLES[response[Fields.COMPONENT_TYPE]]) {
          setCustomMetadata(JSON.stringify(response[Fields.COMPONENT_METADATA]))
        }
      } else {
        if(response['message'] !== false) {
          setMessage(response['message'])
        } else {
          setMessage("An error occurred while saving.")
        }
        setMessageType('danger')
      }
    });

    fetch(process.env.REACT_APP_API_V1_BASE_URL + '/space/' + currentSpaceId, {method: 'GET', credentials: 'include'})
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['name']) {
        setSpaceName(response['name'])
      }
    });

    // document.addEventListener('keydown', handleKeyPress)
    // return () => {
    //   document.removeEventListener('keydown', handleKeyPress);
    // }
  }, [pSpaceId, pId])

  let checkForFile = (componentId) => {
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/file/check/" + componentId, {method: 'GET', credentials: 'include'})
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        setFileExists(true)
      } else if(response['code'] !== 'chautec.component.file.not.found.id') {
        setMessage("An error occurred while checking for any associated file.")
        setMessageType("danger")
      }
    }).catch((error) => {
      setMessage("An error occurred while checking for any associated file.")
      setMessageType("danger")
    });
  }

  let removeFile = () => {
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/file/" + component[Fields.COMPONENT_ID], {method: 'DELETE', credentials: 'include'})
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        setFileExists(false)
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while removing the associated file.")
        setMessageType("danger")
      }
    });
  }

  let setComponentDefaults = c => {
    if(c[Fields.COMPONENT_TYPE] === Constants.TYPE_BLOCK) {
      if(!c[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH]) {
        c[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] = Constants.DEFAULT_BLOCK_WIDTH
      }
      if(!c[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_MOBILE_WIDTH]) {
        c[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_MOBILE_WIDTH] = Constants.DEFAULT_BLOCK_WIDTH
      }
      if(!c[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR]) {
        c[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] = Constants.DEFAULT_FRAME_COLOR
      }
    } else if(c[Fields.COMPONENT_TYPE] === Constants.TYPE_FOLDER) {
      if(!c[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR]) {
        c[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] = Constants.DEFAULT_FRAME_COLOR
      }
    }
    return c
  }

  let handleChangeField = (e, field, innerKey) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    updateComponent(value, field, innerKey)
  }
  let updateComponent = (value, field, innerKey) => {
    let currentComponent = JSON.parse(JSON.stringify(component))
    if(innerKey) {
      currentComponent[innerKey][field] = value
    } else {
      currentComponent[field] = value
    }
    setComponent(currentComponent)
  }
  let onEditorMount = (editor, monaco) => {
    setTimeout(function() {
      editor.getAction('editor.action.formatDocument').run()
    }, 300)
  }
  let onEditorChange = (newValue, field, e) => {
    updateComponent(newValue, field)
  }
  let onEditorChangeCustomMetadata = (newValue) => {
    setCustomMetadata(newValue)
  }

  let saveContent = (close) => {
    // If saving custom type
    let metadataUpdates = null
    if(!Constants.TYPE_TITLES[component[Fields.COMPONENT_TYPE]]) {
      try {
        metadataUpdates = JSON.parse(customMetadata)
      } catch(e) {
        setMessage("Invalid metadata entered. Please ensure it is valid JSON.")
        setMessageType("danger")
        return
      }
    }

    setMessage('')
    setMessageType('info')
    setButtonsDisabled(true)
    setFileExists(false)

    let fileUploadFormData = null
    let newName = null
    if (component[Fields.COMPONENT_TYPE] === Constants.TYPE_FILE) {
      let input = document.querySelector('#file-upload')
      if(input.files.length > 0) {
        let file = input.files[0]
        fileUploadFormData = new FormData()
        fileUploadFormData.append('fileUpload', file)

        let fileExtension = null
        let fileNameSplit = file.name.split(".")
        if (fileNameSplit.length > 1) {
          let lastSplit = fileNameSplit[fileNameSplit.length - 1]
          // Upload file has extension
          if (lastSplit.length >= 2 && lastSplit.length <= 4) {
            fileExtension = lastSplit
            updateComponent(fileExtension, Fields.COMPONENT_METADATA_MIME_TYPE, Fields.COMPONENT_METADATA)
          }
        }

        // If the component has no name set, use the file's
        if (!component[Fields.COMPONENT_NAME]) {
          newName = file.name
        } else if(fileExtension) {
          // Add/replace the extension in the component name
          let nameWithoutExtension = component[Fields.COMPONENT_NAME]
          let existingNameSplit = component[Fields.COMPONENT_NAME].split(".")
          // If current component name already has extension
          if (existingNameSplit.length > 1) {
            let existingExtension = existingNameSplit.pop()
            if (existingExtension.length >= 2 && existingExtension.length <= 4) {
              nameWithoutExtension = existingNameSplit.join(".")
            }
          }
          newName = nameWithoutExtension + "." + fileExtension
        }
      }
    }

    if (fileUploadFormData != null) {
      let input = document.querySelector('#file-upload')
      let data = new FormData()
      data.append('fileUpload', input.files[0])
      fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/file/" + component[Fields.COMPONENT_ID], {
        method: 'POST', credentials: 'include',
        body: data
      })
      .then(HttpUtil.authThenHandler)
      .then((response) => {
        return response.json();
      })
      .then(response => {
        if(response['success'] === true) {
          saveActualComponent(close, newName, response[Fields.COMPONENT_METADATA])
        } else if(response['message'] !== false) {
          setMessage(response['message'])
          setMessageType("danger")
        } else {
          setMessage("An error occurred while uploading.")
          setMessageType("danger")
        }
      }).catch((error) => {
        setMessage("An error occurred while uploading.")
        setMessageType("danger")
      }).finally(() => {
        setButtonsDisabled(false)
        setFileExists(true)
        input.value = ''
      });
    } else {
      saveActualComponent(close, null, metadataUpdates)
      if(component[Fields.COMPONENT_TYPE] === Constants.TYPE_FILE) {
        checkForFile(component[Fields.COMPONENT_ID])
      }
    }
  }

  let saveActualComponent = (close, newName = null, metadataUpdates = null) => {
    setButtonsDisabled(true)

    let metadata = component[Fields.COMPONENT_METADATA]
    if(metadataUpdates) {
      metadata = Object.assign(metadata, metadataUpdates)
      updateComponent(metadata, Fields.COMPONENT_METADATA)
    }

    let updates = {
      [Fields.COMPONENT_ID]: component[Fields.COMPONENT_ID], [Fields.COMPONENT_CONTENT]: component[Fields.COMPONENT_CONTENT],
      [Fields.COMPONENT_PUBLISHED]: component[Fields.COMPONENT_PUBLISHED], [Fields.COMPONENT_VISIBLE]: component[Fields.COMPONENT_VISIBLE],
      [Fields.COMPONENT_METADATA]: metadata, [Fields.COMPONENT_DATE]: component[Fields.COMPONENT_DATE], [Fields.COMPONENT_TAGS]: component[Fields.COMPONENT_TAGS]
    }
    if (!Constants.RESERVED_KEYS.includes(component[Fields.COMPONENT_KEY])) {
      updates[Fields.COMPONENT_KEY] = component[Fields.COMPONENT_KEY]
    }
    if(newName) {
      updates[Fields.COMPONENT_NAME] = newName
      updateComponent(newName, Fields.COMPONENT_NAME)
    } else {
      updates[Fields.COMPONENT_NAME] = component[Fields.COMPONENT_NAME]
    }

    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/" + component[Fields.COMPONENT_ID], {
      method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(updates), credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        setMessage("Component has been successfully saved.")
        setMessageType("success")

        if(close) {
          history.push('/component/' + spaceId + '/' + component.parents[0].id)
        }
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while saving.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let confirmDelete = () => {
    setMessage('')
    setMessageType('info')
    setButtonsDisabled(true)
    setShowConfirmDelete(false)
    
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/" + component[Fields.COMPONENT_ID], {
      method: 'DELETE', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        if(component.parents && component.parents.length > 0 && component.parents[0].id) {
          history.push('/component/' + spaceId + '/' + component.parents[0].id)
        } else {
          history.push('/component/' + spaceId)
        }
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while deleting.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let confirmMove = () => {
    setMoveMessage('')
    setButtonsDisabled(true)
    
    let updates = {id: component.id, parentId: parseInt(moveParentId)}
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/" + component[Fields.COMPONENT_ID], {
      method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(updates), credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        setShowConfirmMove(false)
        history.push('/component/' + spaceId + '/' + moveParentId)
      } else if(response['message'] !== false) {
        setMoveMessage(response['message'])
      } else {
        setMoveMessage("An error occurred while moving.")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let renderComponentOptions = () => {
    switch(component[Fields.COMPONENT_TYPE]) {
      case Fields.COMPONENT_BLOCK:
        return <>
          <div className="row">
            <div className="col-6 col-lg-3 col-xl-2">
              <div className="form-group mt-2 ml-3">
                <label className="form-check">
                  <input className="form-check-input" type="checkbox" checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME] === true}
                    onChange={e => handleChangeField(e, 'frame', Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Include Frame</span>
                </label>
              </div>
            </div>
            {component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME] === true &&
            <>
              <div className="col-6 col-lg-3 col-xl-2">
                <div className="form-group mt-2 ml-3">
                  <label className="form-check">
                    <input className="form-check-input" type="checkbox" checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_COLLAPSE] === true}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_COLLAPSE, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Collapse</span>
                  </label>
                </div>
              </div>
            </>
            }
          </div>
          {component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME] === true &&
            <fieldset className="mt-2 ml-3">
              <label className="form-label">Frame Color</label>
              <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="form-check">
                    <input name="frame-color" type="radio" value="primary" className="form-check-input" defaultChecked
                      checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "primary"}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Primary</span>
                  </label>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="form-check">
                    <input name="frame-color" type="radio" value="info" className="form-check-input"
                      checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "info"}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Info</span>
                  </label>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="form-check">
                    <input name="frame-color" type="radio" value="danger" className="form-check-input"
                      checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "danger"}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Danger</span>
                  </label>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="form-check">
                    <input name="frame-color" type="radio" value="success" className="form-check-input"
                      checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "success"}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Success</span>
                  </label>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="form-check">
                    <input name="frame-color" type="radio" value="warning" className="form-check-input"
                      checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "warning"}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Warning</span>
                  </label>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="form-check">
                    <input name="frame-color" type="radio" value="dark" className="form-check-input"
                      checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "dark"}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Dark</span>
                  </label>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="form-check">
                    <input name="frame-color" type="radio" value="secondary" className="form-check-input"
                      checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "secondary"}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Secondary</span>
                  </label>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <label className="form-check">
                    <input name="frame-color" type="radio" value="light" className="form-check-input"
                      checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "light"}
                      onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                    <span className="form-check-label">Light</span>
                  </label>
                </div>
              </div>
            </fieldset>
          }
          <fieldset className="mt-3 ml-3">
            <label className="form-label">Desktop Width</label>
            <div className="row">
              <div className="col-12">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="0" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "0"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Hidden</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="1" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "1"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">8.33%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="2" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "2"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">16.66%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="3" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "3"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">25%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="4" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "4"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">33.33%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="5" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "5"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">41.66%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="6" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "6"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">50%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="7" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "7"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">58.33%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="8" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "8"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">66.66%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="9" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "9"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">75%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="10" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "10"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">83.33%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="11" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "11"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">91.66%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="desktop-width" type="radio" value="12" className="form-check-input" defaultChecked
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] === "12" || component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_DESKTOP_WIDTH] == null}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_DESKTOP_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">100%</span>
                </label>
              </div>
            </div>
          </fieldset>
          <fieldset className="mt-3 ml-3">
            <label className="form-label">Mobile Width</label>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-2">
                <label className="form-check">
                  <input name="mobile-width" type="radio" value="0" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_MOBILE_WIDTH] === "0"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_MOBILE_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Hidden</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="mobile-width" type="radio" value="3" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_MOBILE_WIDTH] === "3"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_MOBILE_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">25%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="mobile-width" type="radio" value="6" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_MOBILE_WIDTH] === "6"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_MOBILE_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">50%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="mobile-width" type="radio" value="9" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_MOBILE_WIDTH] === "9"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_MOBILE_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">75%</span>
                </label>
              </div>
              <div className="col-6 col-sm-3 col-md-2">
                <label className="form-check">
                  <input name="mobile-width" type="radio" value="12" className="form-check-input" defaultChecked
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_MOBILE_WIDTH] === "12" || component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_MOBILE_WIDTH] == null}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_MOBILE_WIDTH, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">100%</span>
                </label>
              </div>
            </div>
          </fieldset>
        </>
      case Fields.COMPONENT_URL:
        return <div className="form-group mt-3 ml-3">
          <label className="form-check">
            <input className="form-check-input" type="checkbox" checked={component[Fields.COMPONENT_METADATA].newWindow === true}
              onChange={e => handleChangeField(e, 'newWindow', Fields.COMPONENT_METADATA)}/>
            <span className="form-check-label">Open in new window</span>
          </label>
        </div>
      case Constants.TYPE_PAGE:
        return <div className="row mt-2">
          <div className="col-sm-5">
            <div className="form-group mb-0">
              <label className="form-label" htmlFor="component-metadata-metaTitle">Meta Title</label>
              <input id="component-metadata-metaTitle" type="text" className="form-control"
                autoComplete="off" value={component[Fields.COMPONENT_METADATA].metaTitle} placeholder={component[Fields.COMPONENT_NAME]}
                onChange={e => handleChangeField(e, 'metaTitle', Fields.COMPONENT_METADATA)}/>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="form-group mb-0">
              <label className="form-label" htmlFor="component-metadata-metaDescription">Meta Description</label>
              <input id="component-metadata-metaDescription" type="text" className="form-control"
                autoComplete="off" value={component[Fields.COMPONENT_METADATA].metaDescription}
                onChange={e => handleChangeField(e, 'metaDescription', Fields.COMPONENT_METADATA)}/>
            </div>
          </div>
        </div>
      case Fields.COMPONENT_FOLDER:
        return <>
          <div className="row mt-2">
            <div className="col-6 col-lg-3 col-xl-2">
              <div className="form-group mt-2 ml-3">
                <label className="form-check">
                  <input className="form-check-input" type="checkbox" checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_COLLAPSE] === true}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_COLLAPSE, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Collapse</span>
                </label>
              </div>
            </div>
          </div>
          <fieldset className="mt-2 ml-3">
            <label className="form-label">Frame Color</label>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <label className="form-check">
                  <input name="frame-color" type="radio" value="primary" className="form-check-input" defaultChecked
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "primary"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Primary</span>
                </label>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <label className="form-check">
                  <input name="frame-color" type="radio" value="info" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "info"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Info</span>
                </label>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <label className="form-check">
                  <input name="frame-color" type="radio" value="danger" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "danger"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Danger</span>
                </label>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <label className="form-check">
                  <input name="frame-color" type="radio" value="success" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "success"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Success</span>
                </label>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <label className="form-check">
                  <input name="frame-color" type="radio" value="warning" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "warning"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Warning</span>
                </label>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <label className="form-check">
                  <input name="frame-color" type="radio" value="dark" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "dark"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Dark</span>
                </label>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <label className="form-check">
                  <input name="frame-color" type="radio" value="secondary" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "secondary"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Secondary</span>
                </label>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <label className="form-check">
                  <input name="frame-color" type="radio" value="light" className="form-check-input"
                    checked={component[Fields.COMPONENT_METADATA][Fields.COMPONENT_METADATA_FRAME_COLOR] === "light"}
                    onChange={e => handleChangeField(e, Fields.COMPONENT_METADATA_FRAME_COLOR, Fields.COMPONENT_METADATA)}/>
                  <span className="form-check-label">Light</span>
                </label>
              </div>
            </div>
          </fieldset>
        </>
      default:
        return <></>
    }
  }

  let renderComponentEditor = () => {
    switch(component[Fields.COMPONENT_TYPE]) {
      case Constants.TYPE_PAGE:
      case Constants.TYPE_BLOCK:
        return <div className="form-group">
          <h4 className="mt-4 mb-3">Content Editor:</h4>
          <CKEditor
            editor={Editor}
            config={editorConfiguration}
            data={component[Fields.COMPONENT_CONTENT]}
            onChange={(event, editor) => {
              const data = editor.getData()
              updateComponent(data, Fields.COMPONENT_CONTENT)
            }}
          />
        </div>
      case Constants.TYPE_TXT:
      case Constants.TYPE_JS:
      case Constants.TYPE_JSON:
      case Constants.TYPE_CSS:
      case Constants.TYPE_XML:
      case Constants.TYPE_HTML:
        return <div className="form-group">
          {component[Fields.COMPONENT_TYPE] !== Constants.TYPE_HTML &&
            <div className="form-group mt-4">
              <b>Direct URL:</b>&nbsp;&nbsp;
              <a href={process.env.REACT_APP_API_V1_BASE_URL + "/component/text/" + component[Fields.COMPONENT_ID]} target="_blank" rel="noopener noreferrer" className="mr-1">
                {process.env.REACT_APP_API_V1_BASE_URL + "/component/text/" + component[Fields.COMPONENT_ID]}
              </a>
              <button className="btn" onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_V1_BASE_URL + "/component/text/" + component[Fields.COMPONENT_ID])}>
                <i class="fas fa-copy"></i>
              </button>
              <br/>
              <b>Alternative URL:</b>&nbsp;&nbsp;
              <a href={process.env.REACT_APP_API_V1_BASE_URL + "/component/text/" + spaceId + "/" + component[Fields.COMPONENT_KEY]} target="_blank" rel="noopener noreferrer" className="mr-1">
                {process.env.REACT_APP_API_V1_BASE_URL + "/component/text/" + spaceId + "/" + component[Fields.COMPONENT_KEY]}
              </a>
              <button className="btn" onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_V1_BASE_URL + "/component/text/" + spaceId + "/" + component[Fields.COMPONENT_KEY])}>
                <i class="fas fa-copy"></i>
              </button>
            </div>
          }
          <h4 className="mt-4 mb-3">Code Editor:</h4>
          <MonacoEditor
            height="550"
            language={component[Fields.COMPONENT_TYPE]}
            theme="vs-light"
            value={component[Fields.COMPONENT_CONTENT]}
            onChange={(n, e) => onEditorChange(n, Fields.COMPONENT_CONTENT, e)}
            options={monacoOptions}
            editorDidMount={(e, m) => onEditorMount(e, m)}
          />
        </div>
      case Constants.TYPE_URL:
        return <div className="form-group">
          <h4 className="mt-4 mb-2">URL:</h4>
          <input type="text" className="form-control" autoComplete="off" value={component[Fields.COMPONENT_CONTENT]}
            onChange={e => handleChangeField(e, Fields.COMPONENT_CONTENT)}/>
        </div>
      case Constants.TYPE_FILE:
        return <div className="form-group mt-4">
          <label htmlFor="file-upload" className="form-label w-100">File Upload</label>
          <input id="file-upload" type="file" />
          {fileExists &&
            <div className="form-group mt-4">
              <b>File URL:</b>&nbsp;&nbsp;
              <a href={process.env.REACT_APP_API_V1_BASE_URL + "/component/file/" + component.id} target="_blank" rel="noopener noreferrer" className="mr-1">
                {process.env.REACT_APP_API_V1_BASE_URL + "/component/file/" + component.id}
              </a>
              <button className="btn" onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_V1_BASE_URL + "/component/file/" + component.id)}>
                <i class="fas fa-copy"></i>
              </button>
              <br/>
              <a href="#delete" onClick={e => removeFile()} className="text-danger"><i className="fas fa-times"></i> remove</a>
            </div>
          }
      </div>
      case Constants.TYPE_MENU:
      case Constants.TYPE_FOLDER:
      case Constants.TYPE_TITLE:
      case "":
      case null:
        return <span></span>
      default:
        return <div className="form-group">
          <h4 className="mt-4 mb-3">Content:</h4>
          <MonacoEditor
            height="450"
            language="text"
            theme="vs-light"
            value={component[Fields.COMPONENT_CONTENT]}
            onChange={(n, e) => onEditorChange(n, Fields.COMPONENT_CONTENT, e)}
            options={monacoArbitraryOptions}
            editorDidMount={(e, m) => onEditorMount(e, m)}
          />
          <h5 className="mt-4 mb-3">Metadata (JSON):</h5>
          <MonacoEditor
            height="300"
            language="json"
            theme="vs-light"
            value={customMetadata}
            onChange={(n, _) => onEditorChangeCustomMetadata(n)}
            options={monacoOptions}
            editorDidMount={(e, m) => onEditorMount(e, m)}
          />
        </div>
    }
  }
  return (
    <>
    <EditorLayout>
      {
        loading
        ?
          <div>
            {message
            ?
              <div className={'alert alert-' + messageType + ' alert-dismissible'} role="alert">
                  <div className="alert-message">
                    {message}
                  </div>
              </div>
            :
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
          </div>
        :
        <>
          <div className="row mb-2 mb-xl-3">
            <div className="col-sm-8 col-xl-9">
              <div className="parent-breadcrumbs">
                <span>
                  {spaceName}
                  {breadcrumb.length === 0 && <Link to={'/space/' + spaceId}><i className="fas fa-pencil-alt ml-1"></i></Link>}
                </span>
                {breadcrumb.map((value, index) => {
                  return <span key={'back-link-' + index}><Link to={'/component/' + spaceId + '/' + value.id}>
                    {value.name ? value.name : value.key}</Link></span>
                })}
              </div>
              <h2 className="mt-3">{component[Fields.COMPONENT_NAME] ? component[Fields.COMPONENT_NAME] : component[Fields.COMPONENT_KEY]}</h2>
            </div>
            {component.parents && component.parents.length > 0 &&
              <div className="col-sm-4 col-xl-3 ml-auto text-right mt-1">
                <button className="btn btn-secondary btn-sm mr-3" onClick={()=> {setShowConfirmMove(true)}} disabled={buttonsDisabled}>
                  <i className="fas fa-box mr-1"></i> Move</button>
                <button className="btn btn-danger btn-sm" onClick={()=> {setShowConfirmDelete(true)}} disabled={buttonsDisabled}>
                  <i className="fas fa-trash mr-1"></i> Delete</button>
              </div>
            }
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
              <div className="col-6 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <label className="form-label">ID:&nbsp;
                      <span className="font-weight-bold">{component[Fields.COMPONENT_ID]}</span>
                    </label>
                  </div>
                </div>
                <div className="col-6 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <label className="form-label">Type:&nbsp;
                      {(component[Fields.COMPONENT_TYPE] && Object.keys(Constants.TYPE_TITLES).includes(component[Fields.COMPONENT_TYPE]))
                      ?
                        <span className="font-weight-bold">{Constants.TYPE_TITLES[component[Fields.COMPONENT_TYPE]]}</span>
                      :
                      <>
                        <span className="font-weight-bold">{Constants.UNREAl_TYPE_ARBITRARY_TITLE} ({component[Fields.COMPONENT_TYPE]})</span>
                      </>
                      }
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-xl-5">
                  <div className="form-group">
                    <label className="form-label" htmlFor="component-name">Name</label>
                    <input id="component-name" type="text" className="form-control" placeholder="Name"
                      value={component[Fields.COMPONENT_NAME]} onChange={e => handleChangeField(e, Fields.COMPONENT_NAME)}/>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="component-key">Key</label>
                    <input id="component-key" type="text" className="form-control" placeholder="Key" disabled={lockKey}
                      value={component[Fields.COMPONENT_KEY]} onChange={e => handleChangeField(e, Fields.COMPONENT_KEY)} />
                    <small className="form-text text-muted">Key must be unique space-wide.</small>
                  </div>
                </div>
                <div className="col-md-6 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="component-date">Date</label>
                    <br/>
                    <DatePicker
                      className="form-control "
                      selected={new Date(component[Fields.COMPONENT_DATE])}
                      onChange={d => updateComponent(d, Fields.COMPONENT_DATE)}
                      showTimeSelect
                      dateFormat="d MMM, yyyy h:mm aaa"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6 col-lg-3 col-xl-2">
                  <div className="form-group ml-3">
                    <label className="form-check" htmlFor="component-visible">
                      <input id="component-visible" className="form-check-input" type="checkbox" checked={component[Fields.COMPONENT_VISIBLE]}
                        onChange={e => handleChangeField(e, Fields.COMPONENT_VISIBLE)}/>
                      <span className="form-check-label">Visible</span>
                    </label>
                  </div>
                </div>
                <div className="col-6 col-lg-3 col-xl-2">
                  <div className="form-group ml-3">
                    <label className="form-check" htmlFor="component-published">
                      <input id="component-published" className="form-check-input" type="checkbox" checked={component[Fields.COMPONENT_PUBLISHED]}
                        onChange={e => handleChangeField(e, Fields.COMPONENT_PUBLISHED)}/>
                      <span className="form-check-label">Published</span>
                    </label>
                  </div>
                </div>
              </div>
              {renderComponentOptions()}
              {renderComponentEditor()}
              <div className="my-4">
                <label>Tags</label>
                {component[Fields.COMPONENT_TAGS] != null &&
                  <TagsInput
                    value={component[Fields.COMPONENT_TAGS]}
                    onChange={tags => updateComponent(tags, Fields.COMPONENT_TAGS)}
                    name="component-tags" placeHolder="click here to add..."
                  />
                }
              </div>
              {message &&
                <div className={'mt-2 alert alert-' + messageType + ' alert-dismissible'} role="alert">
                    <div className="alert-message">
                      {message}
                    </div>
                </div>
              }
              <div className="row mt-4">
                <div className="col-4">
                  {component.parents.length > 0 &&
                  <Link to={'/component/' + spaceId + '/' + component.parents[0].id}
                    disabled={buttonsDisabled}
                    className="btn btn-square btn-lg btn-light">
                    <i className="fas fa-reply mr-1"></i> Close
                  </Link>
                  }
                </div>
                <div className="col-8 text-right">
                  <button
                    disabled={buttonsDisabled}
                    onClick={e => saveContent(false)}
                    className="btn btn-square btn-lg btn-secondary"
                    type="button"
                  ><i className="fas fa-save mr-1"></i> Save</button>
                  {component.parents.length > 0 &&
                    <button
                      disabled={buttonsDisabled}
                      onClick={e => saveContent(true)}
                      className="btn btn-square btn-lg btn-primary ml-4"
                      type="button"
                    ><i className="fas fa-save mr-1"></i> Save &amp; Close</button>
                  }
                </div>
              </div>
            </div>
          </div>
          {component[Fields.COMPONENT_TYPE] &&
          <>
            <EditorComponentChildren spaceId={spaceId} component={component} />
          </>
          }
          <Modal show={showConfirmDelete} animation={false} onHide={()=> setShowConfirmDelete(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this component and all its children?
              <br/><br/>
              <span className="text-danger font-weight-bold">WARNING: This operation is irreversible.</span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=> setShowConfirmDelete(false)} disabled={buttonsDisabled}>
                Cancel
              </Button>
              <Button variant="danger" onClick={() => confirmDelete()} disabled={buttonsDisabled}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showConfirmMove} animation={false} onHide={()=> setShowConfirmMove(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Move Component</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Enter the ID of the new parent component you would like to move this component to.
              <br/><br/>
              <div className="form-group col-12 col-sm-7 col-lg-5">
                <input type="number" className="form-control" id="new-parent-id" min="0" step="1"
                  onChange={e => setMoveParentId(e.target.value)} placeholder="Parent component ID" />
              </div>
              {moveMessage &&
                <div className={'mt-2 alert alert-danger alert-dismissible'} role="alert">
                    <div className="alert-message">
                      {moveMessage}
                    </div>
                </div>
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=> setShowConfirmMove(false)} disabled={buttonsDisabled}>
                Cancel
              </Button>
              <Button variant="info" onClick={() => confirmMove()} disabled={buttonsDisabled}>
                Move
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      }
    </EditorLayout>
    </>
  );
}

export default EditorComponent;
