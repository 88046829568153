import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";
import update from 'immutability-helper';

import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { Fields } from '../models/fields';
import { Constants } from '../models/constants';

const DND_ITEM_TYPE = 'row'

const Row = ({ spaceId, row, index, moveRow }) => {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)
  
    const [, drop] = useDrop({
      accept: DND_ITEM_TYPE,
      hover(item, monitor) {
        if (!dropRef.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveRow(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
  
    const [{ isDragging }, drag, preview] = useDrag({
      item: { type: DND_ITEM_TYPE, index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    })
  
    const opacity = isDragging ? 0 : 1
  
    preview(drop(dropRef))
    drag(dragRef)
  
    return (
        <tr ref={dropRef} style={{ opacity }}>
            <td className="d-none d-sm-table-cell">
                <Link to={'/component/' + spaceId + '/' + row.id}>{row.key}</Link>
            </td>
            <td className="d-none d-sm-table-cell">{row.name}</td>
            <td className="d-none d-sm-table-cell">
              {(row.componentType && Object.keys(Constants.TYPE_TITLES).includes(row.componentType))
                ?
                  <span>{Constants.TYPE_TITLES[row.componentType]}</span>
                :
                  <span>{Constants.UNREAl_TYPE_ARBITRARY_TITLE} ({row.componentType})</span>
              }
            </td>
            <td className="d-none d-sm-table-cell">
              {row.published ? <span className="badge badge-success">published</span> : <span className="badge badge-danger">unpublished</span>}
            </td>
            <td className="d-none d-lg-table-cell">{new Date(row.date).toLocaleTimeString([], Constants.PARSE_TIME_OPTIONS)}</td>
            <td ref={dragRef} className="text-center draggable-cell"><i className="fas fa-arrows-alt-v"></i></td>
            <td className="d-sm-none d-table-cell">
                <Link to={'/component/' + spaceId + '/' + row.id}>{row.key}</Link><br/>
                {row.name}
            </td>
            <td className="d-sm-none d-table-cell">
              {(row.componentType && Object.keys(Constants.TYPE_TITLES).includes(row.componentType))
                ?
                  <span>{Constants.TYPE_TITLES[row.componentType]}</span>
                :
                  <span>{row.componentType}</span>
              }<br/>
              {row.published ? <span className="badge badge-success">published</span> : <span className="badge badge-danger">unpublished</span>}
            </td>
        </tr>
    )
}

const EditorComponentChildren = ({ spaceId, component }) => {
    const [records, setRecords] = useState(component[Fields.COMPONENT_ITEMS])
    
    const moveRow = (dragIndex, hoverIndex) => {
      const dragRecord = records[dragIndex]
      let newRecords = update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
      let sequence = 1
      let idAndSequence = []
      for(let i = 0; i < newRecords.length; i++) {
          newRecords[i].sequence = sequence
          idAndSequence.push({id: newRecords[i].id, sequence: sequence})
          sequence ++
      }
      setRecords(newRecords)
      let updateJson = {sequences: idAndSequence}
      fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/sequence", {
        method: 'PUT', credentials: 'include', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: JSON.stringify(updateJson),
      })
    }

    return (
    <>
        <h4 className="mb-3">Components:</h4>
        <div className="card flex-fill">
        <DndProvider backend={HTML5Backend}>
            <table className="table table-hover my-0">
            <thead>
                <tr>
                    <th className="d-none d-sm-table-cell">Key</th>
                    <th className="d-none d-sm-table-cell">Name</th>
                    <th className="d-none d-sm-table-cell">Type</th>
                    <th className="d-none d-sm-table-cell">Status</th>
                    <th className="d-none d-lg-table-cell">Date</th>
                    <th className="d-none d-sm-table-cell text-center">Order</th>
                </tr>
            </thead>
            <tbody>
                {records.map((value, index) => {
                    return <Row key={'child-component-row-' + index} spaceId={spaceId} row={value} index={index} moveRow={moveRow} />
                })}
            </tbody>
            <tfoot>
                <tr>
                <td colSpan="5" className="text-center pt-4">
                  <Link to={'/create-component/' + spaceId + '/' + component.id + '/' + component.componentType}>
                    <i className="fas fa-plus-circle"></i> Add Component</Link>
                </td>
                </tr>
            </tfoot>
            </table>
        </DndProvider>
        </div>
    </>
    );
};

export default EditorComponentChildren;
