import React, { useState, useEffect } from 'react';
import {
  useLocation,
  Link
} from "react-router-dom";

import EditorLayout from "./common/EditorLayout";
import { Constants } from '../models/constants';
import { HttpUtil } from '../models/http-utils';


const SearchComponent = (props) => {
  const [loading, setLoading] = useState(true)

  const [searchResults, setSearchResults] = useState([])

  const search = useLocation().search;
  const terms = new URLSearchParams(search).get('q')

  const [spacesMap, setSpacesMap] = useState({})

  useEffect(() => {
    if(terms) {
      fetch(process.env.REACT_APP_API_V1_BASE_URL + '/space/all', {method: 'GET', credentials: 'include'})
      .then(HttpUtil.authThenHandler)
      .then((response) => {
        return response.json();
      })
      .then(response => {
        let spacesTempMap = {}
        if(Array.isArray(response['spaces'])) {
          response['spaces'].forEach(r => {
            spacesTempMap[r.id] = r.name
          })
        }
        setSpacesMap(spacesTempMap)
      })

      let searchTerms = {'terms': terms}
      fetch(process.env.REACT_APP_API_V1_BASE_URL + "/component/search", {
        method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: JSON.stringify(searchTerms), credentials: 'include'
      })
      .then(HttpUtil.authThenHandler)
      .then((response) => {
        return response.json();
      })
      .then(response => {
        if(Array.isArray(response['components'])) {
          setSearchResults(response['components'])
        }
      }).finally(() => {
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setSpacesMap(props.spacesMap)
  }, [props.spacesMap])

  return (
    <>
    <EditorLayout>
      {
        loading
        ?
          <div>
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        :
        <>
          <h2 className="mb-3">Search "{terms}"</h2>
          <div className="card">
            <div className="card-body p-0">
              <table className="table table-hover my-0">
                <thead>
                  <tr>
                      <th className="d-none d-sm-table-cell">Key</th>
                      <th className="d-none d-sm-table-cell">Name</th>
                      <th className="d-none d-sm-table-cell">Type</th>
                      <th className="d-none d-sm-table-cell">Space</th>
                      <th className="d-none d-sm-table-cell">Status</th>
                      <th className="d-none d-lg-table-cell">Date</th>
                  </tr>
              </thead>
                <tbody>
                    {searchResults.map((row, index) => {
                        return <tr key={'component-row-' + index}>
                          <td className="d-none d-sm-table-cell">
                              <Link to={'/component/' + row.spaceId + '/' + row.id}>{row.key}</Link>
                          </td>
                          <td className="d-none d-sm-table-cell">{row.name}</td>
                          <td className="d-none d-sm-table-cell">
                            {(row.componentType && Object.keys(Constants.TYPE_TITLES).includes(row.componentType))
                              ?
                                <span>{Constants.TYPE_TITLES[row.componentType]}</span>
                              :
                              <>
                                <span>{Constants.UNREAl_TYPE_ARBITRARY_TITLE} ({row.componentType})</span>
                              </>
                            }
                          </td>
                          <td className="d-none d-sm-table-cell">{spacesMap && spacesMap[row.spaceId] ? spacesMap[row.spaceId] : ''}</td>
                          <td className="d-none d-sm-table-cell">
                            {row.published ? <span className="badge badge-success">published</span> : <span className="badge badge-danger">unpublished</span>}
                          </td>
                          <td className="d-none d-lg-table-cell">
                            {new Date(row.date).toLocaleTimeString([], Constants.PARSE_TIME_OPTIONS)}
                          </td>
                          <td className="d-sm-none d-table-cell">
                              <Link to={'/component/' + row.spaceId + '/' + row.id}>{row.key}</Link><br/>
                              <b>{row.name}</b><br/>
                              ({spacesMap && spacesMap[row.spaceId] ? spacesMap[row.spaceId] : ''})
                          </td>
                          <td className="d-sm-none d-table-cell">
                            {(row.componentType && Object.keys(Constants.TYPE_TITLES).includes(row.componentType))
                              ?
                                <span>{Constants.TYPE_TITLES[row.componentType]}</span>
                              :
                                <span>{row.componentType}</span>
                            }<br/>
                            {row.published ? <span className="badge badge-success">published</span> : <span className="badge badge-danger">unpublished</span>}
                          </td>
                      </tr>
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      }
    </EditorLayout>
    </>
  );
}

export default SearchComponent;
