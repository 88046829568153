import React, { useState, useEffect } from 'react';
import {
  useParams,
  Link
} from "react-router-dom";
import { useHistory } from "react-router";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import EditorLayout from "./common/EditorLayout";
import { HttpUtil } from '../models/http-utils';


let emptyUser = () => {
  return {'id': 0, 'email': '', 'active': true}
}

const UserComponent = () => {
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('info')
  const [loading, setLoading] = useState(true)
  const [buttonsDisabled, setButtonsDisabled] = useState(true)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [visibleApiKey, setVisibleApiKey] = useState("••••••••••••••••••••••••••••••")

  const [newUser, setNewUser] = useState(true)
  const [userRecord, setUserRecord] = useState(emptyUser())

  let { pUserId } = useParams()
  const history = useHistory()

  useEffect(() => {
    let isNewUser = !pUserId
    setNewUser(isNewUser)

    if(!isNewUser) {
      fetch(process.env.REACT_APP_API_V1_BASE_URL + "/user/" + pUserId, {method: 'GET', credentials: 'include'})
      .then(HttpUtil.authThenHandler)
      .then((response) => {
        return response.json();
      })
      .then(response => {
        if(response['id']) {
            setLoading(false)
            setButtonsDisabled(false)
            setUserRecord(response)
        } else {
          if(response['message'] !== false) {
            setMessage(response['message'])
          } else {
            setMessage("An error occurred while saving.")
          }
          setMessageType('danger')
        }
      })
    } else {
      setLoading(false)
      setButtonsDisabled(false)
    }
  }, [pUserId])

  let handleChangeField = (e, field, innerKey) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    updateUserRecord(value, field, innerKey)
  }
  let updateUserRecord = (value, field, innerKey) => {
    let currentUser = JSON.parse(JSON.stringify(userRecord))
    if(innerKey) {
      currentUser[innerKey][field] = value
    } else {
      currentUser[field] = value
    }
    setUserRecord(currentUser)
  }

  let saveUser = () => {
    if(userRecord.password && userRecord.password !== userRecord.confirmPassword) {
      setMessage("Please make sure your Password and Confirm Password are identical.")
      setMessageType("danger")
    } else {
      setMessage('')
      setMessageType('info')
      setButtonsDisabled(true)

      if(newUser) {
        saveNewUser()
      } else {
        saveExistingUser()
      }
    }
  }

  let saveNewUser = () => {
    let newUser = JSON.parse(JSON.stringify(userRecord))
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/user", {
      method: 'POST', credentials: 'include',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(newUser),
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        history.push('/settings/users');
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while saving.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let saveExistingUser = () => {
    let updates = {id: userRecord.id, email: userRecord.email, active: userRecord.active}
    if (userRecord.password || userRecord.confirmPassword) {
      if(userRecord.password !== userRecord.confirmPassword) {
        setMessage("Passwords do not match.")
        setMessageType("danger")
        setButtonsDisabled(false)
        return
      }
      updates['password'] = userRecord.password
    }

    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/user/" + userRecord.id, {
      method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(updates), credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        setMessage("User has been successfully saved.")
        setMessageType("success")
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while saving.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let confirmDelete = () => {
    setMessage('')
    setMessageType('info')
    setButtonsDisabled(true)
    setShowConfirmDelete(false)
    
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/user/" + userRecord.id, {
      method: 'DELETE', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        history.push('/settings/users')
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while deleting.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let regenerateKey = () => {
    setMessage('')
    setMessageType('info')
    setButtonsDisabled(true)
    
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/user/key/" + userRecord.id, {
      method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true && response['key']) {
        setVisibleApiKey(response['key'])
        updateUserRecord(true, "apiKeyEnabled")
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while generating a new API key.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  let removeKey = () => {
    setMessage('')
    setMessageType('info')
    setButtonsDisabled(true)
    
    fetch(process.env.REACT_APP_API_V1_BASE_URL + "/user/key/" + userRecord.id, {
      method: 'DELETE', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, credentials: 'include'
    })
    .then(HttpUtil.authThenHandler)
    .then((response) => {
      return response.json();
    })
    .then(response => {
      if(response['success'] === true) {
        setVisibleApiKey("")
        updateUserRecord(false, "apiKeyEnabled")
      } else if(response['message'] !== false) {
        setMessage(response['message'])
        setMessageType("danger")
      } else {
        setMessage("An error occurred while removing API key.")
        setMessageType("danger")
      }
    }).finally(() => {
      setButtonsDisabled(false)
    });
  }

  return (
    <>
    <EditorLayout>
      {
        loading
        ?
          <div>
            {message
            ?
              <div className={'alert alert-' + messageType + ' alert-dismissible'} role="alert">
                  <div className="alert-message">
                    {message}
                  </div>
              </div>
            :
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
          </div>
        :
        <>
          <div className="row mb-2 mb-xl-3">
            <div className="col-auto d-none d-sm-block">
              <Link to={'/settings/users'}>
                <i className="fas fa-arrow-circle-left"></i> All Users</Link>
              <h2 className="mt-3 mb-3">{newUser ? 'Create' : 'Edit'} User</h2>
            </div>
            {!newUser &&
              <div className="col-auto ml-auto text-right mt-n1">
                <button className="btn btn-secondary btn-sm" onClick={()=> {setShowConfirmDelete(true)}}>
                  <i className="fas fa-trash mr-1"></i> Delete</button>
              </div>
            }
          </div>
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label className="form-label" htmlFor="user-email">Email</label>
                <input id="user-email" type="text" className="form-control" placeholder="Email" autoComplete="false"
                  value={userRecord.email} onChange={e => handleChangeField(e, 'email')}/>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="user-password">Password</label>
                <input id="user-password" type="password" className="form-control" placeholder="Password" autoComplete="false"
                  value={userRecord.password} onChange={e => handleChangeField(e, 'password')}/>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="user-confirm-password">Confirm Password</label>
                <input id="user-confirm-password" type="password" className="form-control" placeholder="Confirm password" autoComplete="false"
                  value={userRecord.confirmPassword} onChange={e => handleChangeField(e, 'confirmPassword')}/>
              </div>
              <div className="form-group mt-4 mb-4 ml-3">
                <label className="form-check" htmlFor="user-active">
                  <input id="user-active" className="form-check-input" type="checkbox" checked={userRecord.active}
                    onChange={e => handleChangeField(e, 'active')}/>
                  <span className="form-check-label">Active</span>
                </label>
              </div>
              {!newUser &&
                <div className="form-group mt-4 mb-4 ml-2">
                  <label className="form-label">API Key:&nbsp;
                    {userRecord.apiKeyEnabled === true &&
                      <div>
                        <span className="font-weight-bold">{visibleApiKey}</span>&nbsp;
                        (<a href="#remove" onClick={()=> removeKey()} className="text-danger"><i className="fas fa-times"></i> remove</a>)
                      </div>  
                    }
                  </label>
                  <br/>
                  <button
                    disabled={buttonsDisabled}
                    onClick={e => regenerateKey()}
                    className="btn btn-sm btn-danger"
                    type="button"
                  ><i className="fas fa-sync mr-1"></i> Generate New Key</button>
                </div>
              }
              {message &&
                <div className={'alert alert-' + messageType + ' alert-dismissible'} role="alert">
                    <div className="alert-message">
                      {message}
                    </div>
                </div>
              }
              <div className="text-right mt-4">
                <button
                  disabled={buttonsDisabled}
                  onClick={e => saveUser()}
                  className="btn btn-square btn-lg btn-primary"
                  type="button"
                ><i className="fas fa-save mr-1"></i> Save</button>
              </div>
            </div>
          </div>
          <Modal show={showConfirmDelete} animation={false} onHide={()=> setShowConfirmDelete(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=> setShowConfirmDelete(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={() => confirmDelete()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      }
    </EditorLayout>
    </>
  );
}

export default UserComponent;
